<template>
        <div class="login-wrap">
        <div class="content">
            <div class="left">
                <div class="right">
                <div class="login-form">
                    <div class="title">
                        <span>欢迎登录</span>
                        <p>{{appName}}</p>
                    </div>
                    <el-form :model="loginData" label-width="68px" :rules="rules" ref="loginData">
                        <el-form-item  prop="username">
                            <el-input v-model="loginData.username" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="loginData.password" placeholder="请输入密码" type="password">
                            </el-input>
                        </el-form-item>
                        <el-button type="primary" class="admin-btn" @click="loginSubmitForm('loginData')" >登录</el-button>
                    </el-form>
                </div>
            </div>
            </div>

        </div>
    </div>
</template>

<script>
import {config} from "@/utils/config";
    export default {
        name:'Login',
        data(){
            return {
                appName: config.app_name,
                loginData:{
                    'username':'',
                    'password':''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 2, max:40, message: '长度在 2 到 40个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 25, message: '长度在 6 到 25个字符', trigger: 'blur'}
                    ]
                },
            }
        },
        methods:{
            loginSubmitForm(fromName){
                // console.log(52,fromName)
                this.$refs[fromName].validate((valid)=>{
                    if(valid){
                        let formData = new FormData();
                        formData.append('username',this.loginData.username);
                        formData.append('password',this.loginData.password);
                        this.$httpteacher.axiosPost(this.$api.login,formData,(res)=>{
                            console.log(59,res)
                            if(res.code == 200){
                                if(res.data.role === 3){
                                    console.log('成功')
                                    this.$message({
                                        type: 'success',
                                        message: '教师登录成功！',
                                        duration: 1000,
                                    })
                                    localStorage.setItem('teacherToken', res.data.token);
                                    localStorage.setItem('username', res.data.username);
                                    localStorage.setItem('adminIcon', res.data.head_portrait);
                                    localStorage.setItem('role', res.data.role);
                                    this.$router.push('/teacher/account/index');
                                }
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.message
                                });
                            }
                        },(err)=>{
                            console.log(err)
                        })
                    } else {
                        return false
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/image/teacherloginbj.jpg");
        background-repeat: no-repeat;
        /*background-size: contain;*/
        overflow: auto;
    }
    .header {
        padding: 20px;
    }
    .content {
        width: 960px;
        height:520px;
        margin: 40px auto;
    }
    .left {
        width: 100%;
        height: 100%;
        float: left;
        background-image: url("../../assets/img/image/teacherbj.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px 0 0 4px;
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        float: right;
        background: rgba(255,255,255,.0);
        border-radius: 0 4px 4px 0;

    }
    .right .el-input{
        width: 340px;
        border:1px solid rgba(177,179,204,1);
        border-radius:4px
    }
    .login-form {
        width: 400px;
        height:300px;
        margin: 0 auto;
        ::v-deep .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #DCDFE6;
            border-radius:4px
        }

        ::v-deep.el-form-item__content{
            margin-left: 30px!important;
        }
        i{
            font-size: 20px;
        }
    }
    ::v-deep.el-form-item{
        margin-bottom: 30px;
    }
    .el-form{
        background: #FFFFFF;
    }
    .title {

        margin-bottom:30px;
    }
    .title span {
        font-size: 30px;
        color: #5794FF;
        margin-left: 30px;
    }
    .title p{
        color: #333333;
        font-size: 18px;
        font-weight: 400;
        margin-left: 30px;
    }
    .admin-btn{
        width:340px;
        /*margin-left: 68px;*/
        margin-top: 30px;
        background:rgba(87,148,255,1);
        border-radius:20px;
        border: none;
        margin-left: 30px;
    }
</style>
